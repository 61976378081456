/* eslint-disable  */

import _ from "lodash";
import moment from "moment";

/**
 * 统一Http请求接口
 * 自动带token请求
 */
const commonApi = require("./config");

const WXGW_SERVER = commonApi.WXGW_SERVER();
const content_SERVER = commonApi.content_SERVER();
const stats_Server = commonApi.stats_Server();

const post = async(url, data, isWxGw) => {
    // 每次取值，防止更新不及时
    let token = `Bearer ${localStorage.token}`;
    if (isWxGw) {
        token = `Bearer ${localStorage.gwtoken}`;
    }
    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("content-type", "application/json"); // 设置 HTTP 头，数据指定为 JSON 格式
    xhr.setRequestHeader("Authorization", token);
    return new Promise((resolve, reject) => {
        xhr.onreadystatechange = function() {
            if (xhr.readyState == 4) {
                if (xhr.status === 200) {
                    var result = JSON.parse(xhr.responseText); // 必须从 responseText 读文本数据
                    resolve(result);
                } else {
                    // console.log("22222222", xhr.readyState, xhr.responseText, xhr);
                    reject(`HTTP API: ${xhr.status},${xhr.responseText}`);
                }
            }
        };
        xhr.send(JSON.stringify(data));
    });
};

/**
 * 封装过期控制代码
 * @param {*} name
 * @returns
 */

export const setLocalStorage = (key1, value) => {
    //获取时间戳
    var curTime = new Date().getTime();
    return localStorage.setItem(
        key1,
        JSON.stringify({
            data: value,
            time: curTime,
        })
    );
};

export const getLocalStorage = (key1, exp, needExp) => {
    const data = localStorage.getItem(key1);

    let dataObjDatatoJson = {};
    //转为对象
    const dataObj = data ? JSON.parse(data) : {};

    if (needExp && exp && new Date().getTime() - dataObj.time > exp) {
        // 已过期，则返回空
        // console.log(
        //   "----",
        //   needExp,
        //   new Date().getTime() - dataObj.time,
        //   exp,
        //   new Date().getTime() - dataObj.time > exp
        // );
    } else {
        if (dataObj.data) {
            dataObjDatatoJson = dataObj.data;
        }
    }
    // if (dataObj.data) {
    //   dataObjDatatoJson = dataObj.data;
    // }

    return dataObjDatatoJson;
};

/**
 * pmScope 用户权限 统计权限 通讯录管理 稿件管理，默认统计权限
 * @returns
 * pmValue
 * pmIndex
 * pmSuper(是否为超管)
 * zdConManager(是否为站段内容管理员)
 * zdTxlManager(是否为站段通讯录管理员)
 * statsMgtSingle (统计超管分项)
 * zdStats(站段统计)
 */
export const getUserPm = (pmScope) => {
    let retData = null;
    let pmSuper = false;
    // 用户权限级别，超管默认3级，站段管理员根据接口返回计算，0 默认无权限
    let pmLevel = 0; // 数据查询权限
    let zdConManager = false;
    let zdTxlManager = false;
    let statsMgtSingle = false;
    let bContentTouGao = false;
    let bContentTouGaoView = false;
    let zdContentView = false;
    let zdMessageView = false;
    let wayConentView = false;
    let wayMessageView = false;
    let zdStats = false;
    const userData = getLocalStorage("statsUserData", getDefaultExpTime());

    if (userData && userData.pmIndex && userData.pmValue) {
        // 之前权限有数组和字符串类型，统一合并
        if (userData.pmIndex && !Array.isArray(userData.pmIndex)) {
            userData.pmIndex = [userData.pmIndex];
        }

        // 超管有全部权限
        if (
            userData.pmIndex.indexOf("990") > -1 ||
            userData.pmIndex.indexOf("999") > -1 ||
            userData.pmIndex.indexOf("950") > -1 // 统计超管
        ) {
            pmSuper = true;
            pmLevel = 2; // 用户权限级别，超管默认2级，数据查询权限的下一级部门信息
        }

        if (userData.pmIndex.indexOf("950") > -1 || userData.pmValue.bStatsArr) {
            statsMgtSingle = true;
        }
        // 站段内容管理员
        if (
            userData.pmIndex.indexOf("500") > -1 &&
            !_.isEmpty(userData.pmValue.zdStats)
        ) {
            zdStats = true;
            pmLevel = userData.level; // 用户权限级别，站段管理员，数据查询权限的下一级部门信息
        }
        // 站段通讯录管理员
        if (
            userData.pmIndex.indexOf("500") > -1 &&
            !_.isEmpty(_.get(userData.pmValue, "txl"))
        ) {
            zdTxlManager = true;
            pmLevel = userData.level; // 用户权限级别，站段管理员，数据查询权限的下一级部门信息
        }

        if (userData.pmValue.bContentTouGao) {
            bContentTouGao = true;
        }

        if (userData.pmValue.bContentTouGaoView) {
            bContentTouGaoView = true;
        }
        if (userData.pmValue.zdContentView) {
            zdContentView = true;
        }
        if (userData.pmValue.zdMessageView) {
            zdMessageView = true;
        }
        if (userData.pmValue.wayConentView) {
            wayConentView = true;
        }
        if (userData.pmValue.wayMessageView) {
            wayMessageView = true;
        }
        // console.log("11111------", userData.pmIndex, _.isEmpty(userData.pmIndex));
        console.log("111111===---------", pmScope, pmLevel);
        if (!_.isEmpty(userData.pmIndex)) {
            // 排除用户无统计权限的情况
            if (pmScope == "通讯录管理") {
                retData = {
                    pmValue: userData.pmValue,
                    pmIndex: userData.pmIndex,
                    pmSuper,
                    zdConManager,
                    zdStats,
                    zdTxlManager,
                    statsPmStatus: pmSuper || zdStats || zdTxlManager, // 用户是否有统计权限
                    statsMgtSingle,
                    bContentTouGao,
                    bContentTouGaoView,
                    level: pmLevel,
                };
            }
            if (pmScope == "稿件管理") {
                retData = {
                    pmValue: userData.pmValue,
                    pmIndex: userData.pmIndex,
                    pmSuper,
                    bContentTouGao,
                    bContentTouGaoView,
                    zdContentView,
                    zdMessageView,
                    wayConentView,
                    wayMessageView,
                    datalevel: pmLevel + 1, // 数据权限，用户权限的下层部门
                    level: pmLevel,
                    statsPmStatus: pmSuper || zdStats || zdTxlManager, // 用户是否有统计权限
                };
            }
            if (pmScope == "统计权限" || !pmScope) {
                if (
                    (userData.pmIndex.indexOf("500") > -1 &&
                        !_.isEmpty(userData.pmValue.zdStats)) ||
                    pmSuper ||
                    statsMgtSingle
                ) {
                    // 站段统计 / 超管 / 统计超管
                    retData = {
                        pmValue: userData.pmValue,
                        pmIndex: userData.pmIndex,
                        pmSuper,
                        zdConManager,
                        zdStats,
                        zdTxlManager,
                        statsMgtSingle,
                        bContentTouGao,
                        bContentTouGaoView,
                        datalevel: pmLevel + 1, // 数据权限，用户权限的下层部门
                        level: pmLevel,
                        statsPmStatus: pmSuper || zdStats || zdTxlManager, // 用户是否有统计权限
                    };
                }
            }
        }
    }
    console.log("getUserPm===---------", retData);

    return retData;
};
/**
 * 从url中读取请求字符串
 * @param {string} name
 * @return {*}
 */
function getQueryString(name) {
    //   debug('name=======', name);
    const r = document.location.href.match(
        // eslint-disable-next-line
        new RegExp(`([\?|&])${name}=([^|&]*)(&|$)`)
    );
    if (r !== null) {
        const str = decodeURI(r[2]);
        const pos = str.indexOf("#/");
        if (pos >= 0) {
            return str.substr(0, pos);
        }
        return str;
    }

    // const r = $location.href.match( // eslint-disable-next-line
    //   new RegExp(`([\?|&])${name}=([^#&]+)`));
    // if (r != null) return decodeURI(r[2]);

    return null;
}
/**
 * 更新token信息
 * @param {*} newToken
 */
export const setGwToken = (newToken) => {
    localStorage.gwtoken = newToken;
};
/**
 * clear token信息
 * @param {*} newToken
 */
export const clearToken = () => {
    localStorage.clear();
};
/**
 * wxGwServer的请求
 */
export const wxGwPost = async(path, data) => {
    const url = `${WXGW_SERVER}${path}`;
    return await post(url, data, true);
};

/**
 * userPmPost的请求
 */
export const userPmPost = async(path, data) => {
    const url = `${content_SERVER}${path}`;
    return await post(url, data, true);
};

/**
 * statsPost的请求
 */
export const statsPost = async(path, data) => {
    const url = `${stats_Server}${path}`;
    return await post(url, data);
};

/**
 *
 * 头条后台的请求
 * 外部用户用户只允许部分界面可以访问
 *
 */

export const wxConPost = async(path, data) => {
    // 判断内部外部用户
    const url = `${content_SERVER}${path}`;
    return await post(url, data);
};

/**
 *
 * 统计请求，为区分不同权限下的用户请求，自动替换地址
 * 1. 根据参数自动拼接替换地址，默认为超管权限
 * 2. 根据不同权限拼接替换地址
 */

export const wxStatsConPost = async(path, data) => {
    // 判断内部外部用户
    let url = `${content_SERVER}${path}`;
    let postData = data;
    const userData = getUserPm();

    // 拼接权限
    if (userData.pmIndex && userData.pmValue) {
        postData = Object.assign(data, {
            pmIndex: userData.pmIndex,
            pmValue: userData.pmValue,
        });
    }
    //
    // 非超级管理员 且 站段管理员 替换地址
    // console.log(
    //   "--用户权限",
    //   userData,
    //   userData.zdStats,
    //   path.indexOf("/wxStats")
    // );
    if (userData.pmValue) {
        if (!userData.pmSuper &&
            userData.zdStats &&
            path.indexOf("/wxStats") > -1
        ) {
            // 替换为站段统计
            const regPath = path.replace(/\/wxStats/g, "/zdWxStats");
            url = `${content_SERVER}${regPath}`;
        }
        // console.log("postData----", url, postData);
        return await post(url, postData);
    } else {
        // alert("暂无权限");
    }
    // 无权限，不可查询数据
};

export const getDefaultExpTime = () => {
    return 7 * 24 * 60 * 60;
};

/**
 *  环比增长率=（本期数-上期数）/上期数×100%
 *  同比增长率=（本期数－同期数）/ 同期数×100%
 * @param {*} newVal  本期数
 * @param {*} oldVal  上期数/同期数
 */
export const getRadio = (newVal, oldVal, fixNum) => {
    let _fixNum = fixNum || 2;
    if (fixNum == 0) {
        _fixNum = 0;
    }
    let _differenceValue = "-";
    if (oldVal) {
        const _rate = ((newVal - oldVal) / oldVal) * 100;
        _differenceValue =
            _rate > 0 ? `+${_rate.toFixed(_fixNum)}%` : `${_rate.toFixed(_fixNum)}%`;
    }
    return _differenceValue;
};

/**
 *  查询环比周期
 * @param {*} newVal
 * @param {*} oldVal
 */
export const getRadioDate = (startDate, endDate) => {
    const allDays = moment(endDate).diff(moment(startDate), "days") + 1;
    const _startDate = moment(startDate)
        .subtract(allDays, "days")
        .format("YYYY-MM-DD");
    const _endDate = moment(endDate)
        .subtract(allDays, "days")
        .format("YYYY-MM-DD");
    console.log("getRadioDate--------", startDate, _startDate, allDays);
    return {
        startDate: _startDate,
        endDate: _endDate,
    };
};

/**
 *  查询环比周期
 * @param {*}
 * @param {*}
 */

export const getYearOnYearBasisDate = (startDate, endDate) => {
    const _startDate = moment(startDate)
        .subtract("1", "year")
        .format("YYYY-MM-DD");
    const _endDate = moment(endDate).subtract("1", "year").format("YYYY-MM-DD");

    // console.log("----", startDate, endDate, _startDate, _endDate);
    return {
        startDate: _startDate,
        endDate: _endDate,
    };
};

/**
 * 秒转换为小时/分钟
 * @param {*} second
 * @returns
 */
export const formatSecond = (second, type, fixedNum) => {
    let divisor = 3600;
    let _fixedNum = 2;
    if (!_.isUndefined(fixedNum)) {
        _fixedNum = fixedNum;
    }
    if ("minute" == type) {
        divisor = 60;
    }

    let hours = second / divisor;
    return `${hours.toFixed(_fixedNum)}`;
};
/**
 * 生产柱状图
 */
export const getOptBar = (title, seriesName, seriesData, xAxis, type) => {
    return {
        title: {
            left: "center",
            text: title,
        },

        xAxis: {
            type: "category",
            data: xAxis,
        },
        yAxis: {
            type: "value",
        },
        series: [{
            name: seriesName,
            data: seriesData,
            type: type || "bar",
            // showBackground: true,
            // backgroundStyle: {
            //   color: "rgba(180, 180, 180, 0.2)",
            // },
            showBackground: true,
            itemStyle: { color: "#83bff6" },
        }, ],

        tooltip: {
            trigger: "axis",
        },

        grid: {
            left: "1%",
            right: "3%",
            bottom: "1%",
            containLabel: true,
        },

        // xAxis: [
        //   {
        //     type: "category",
        //     boundaryGap: false,
        //     data: xAxis,
        //   },
        // ],
        // yAxis: [
        //   {
        //     type: "value",
        //     axisLabel: {
        //       formatter: "{value}",
        //     },
        //   },
        // ],
        // series: [
        //   {
        //     name: seriesName,
        //     type: "bar",
        //     data: seriesData,
        //   },
        // ],
    };
};

/**
 *  保留小数位数
 * @param {*}   number 值
 * @param {*}  length 保留几位小数位数
 */
export const getPointNum = (number, length) => {
    if (typeof number != "number") {
        number = Number(number);
    }

    return Number(number.toFixed(length || 2));
};

/**
 *  小数转换百分比
 * @param {*}  decimal 值
 * @param {*}  length 长度
 */
export const toPercent = (decimal, length) => {
    return (decimal * 100).toFixed(length || 2) + "%";
};

export default {
    clearToken,
    setGwToken,

    wxGwPost, // 微信网关post
    wxConPost, //   头条后台请求
    userPmPost, //  权限服务器post
    statsPost, // 统计服务器post

    wxStatsConPost,

    getLocalStorage,
    setLocalStorage,
    getDefaultExpTime,

    getRadio,
    getPointNum,
    toPercent,
};